// Bootstrap Grid only
//
// Includes relevant variables and mixins for the flexbox grid
// system, as well as the generated predefined classes (e.g., `.col-sm-4`).

//
// Box sizing, responsive, and more
//

@at-root {
  @-ms-viewport { width: device-width; }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";

$grid-breakpoints: (
  // Extra small screen / phone
        xs: 0,
  // Small screen / phone
        sm: 576px,
  // Medium screen / tablet
        md: 768px,
  // Large screen / desktop
        lg: 1100px,
  // Extra large screen / wide desktop
        xl: 1300px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1050px,
        xl: 1600px
);

//
// Grid mixins
//

@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../node_modules/bootstrap/scss/mixins/grid-framework";
@import "../../../node_modules/bootstrap/scss/mixins/grid";

@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/utilities/flex";
