@import "normalize";
@import "bootstrap-grid";
@import "fonts";

$white: #fff;
$black: #3F4B50;
$green: #198356;
$greenlight: #E8F3EE;
$blue: #F2F5F9;
$grey: #E6E6E6;
$greydark: #525D61;

html {
  font-size: 17px;
}
@media screen and (min-width: 10.0em){
  html { font-size: calc( 17px + (24 - 16) * (100vw - 400px) / (800 - 400) ); }
}

@media screen and (min-width: 60em){
  html { font-size: 22px; }
}

body {
  background: #fff;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  color: $black;
  margin: 0;
  padding: 0;
  font-weight: 300;
}

a {
  color: $green;
  text-decoration: none;
  transition: all ease 0.5s;
}

p {
  margin-bottom: 1rem;
  line-height: 1.3;
}
h1,h2,h3,h4,h5 {
  font-weight: 600;
  margin-bottom: 1.2rem;
  font-size: 2.1rem;
  line-height: 1.4;
  word-wrap: break-word
}
h2 {
  font-size: 1.95rem;
  margin-bottom: 1.1rem;
}
h3 {
  font-size: 1.6rem;
  margin-bottom: 1.0rem;
}
h4 {
  font-size: 1.4rem;
  margin-bottom: 1.0rem;
}
h5 {
  font-size: 1.1rem;
  margin-bottom: 0.8rem;
}

img {
  width: auto;
  max-width: 100%;
  height: auto;
}
b {
  font-weight: 600;
}
.toprow {
  border-bottom: 1px solid $grey;
  text-align: center;
  p {
    margin-bottom: 0;
    padding: 0.7rem 0;
  }
}
.desktopshow {
  display: none;
}

.header {
  padding-top: 1rem;
  text-align: center;
}
.quote p {
  font-style: italic;
  line-height: 1.6;
}
.quote p.quelle {
  font-style:normal;
  color: $green;
}

.mobile {
  border-bottom: 1px solid $grey;
  border-top: 1px solid $grey;
  display: flex;
  margin-top: 0.7rem;
}
.mobiledisplay {
  padding-left: 0;
  padding-right: 0;
}
.iconsingle {
  flex: 33%;
}
.iconsingle a{
  display: block;
  border-right: 1px solid $grey;
  padding: 0.8rem 0;
  line-height: 2.5rem;
  text-align: center;
}
.mobile .iconsingle:last-child a{
  border: 0;
}
.navigation {
  padding: 0;
}
nav {
  background: $blue;
  font-weight: 600;
  font-size: 1.3rem;
  li {
    padding: 1.0rem 0;
    border-bottom: 1px solid $white;
  }
  a {
    display: block;
  }
}
.bluekasten {
  background: $blue;
  padding: 2rem;
  margin: 2rem 0;
  line-height: 1.5;
}
.openbg {
  background: $blue;
}
.navigation {
  display: none;
}
.sticky-wrapper {
  position: relative;
  z-index: 9000;
}
.header {
  background: $white;

  position: relative;
}
.erfolg {
  text-align: center;
  margin: 4rem 0;
}
input,textarea {
  width: 100%;
  border: 1px solid $grey;
  padding: 0.6rem;
  font-size: 0.9rem;
  font-family: "Source Sans Pro", Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  margin-bottom: 1rem;
}
input[type="submit"]{
  background: $green;
  cursor: pointer;
  color: #FFF;
  font-weight: 600;
  border: 0;
  &:hover {
    opacity: 0.8;
  }
}
footer {
  background: $black;
  padding: 2rem 0;
  font-size: 0.9rem;
  color: $white;
}
.footerlogo {
  margin-bottom: 2rem;
  text-align: center;
}
.footernav {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid $greydark;
  ul {
    width: 100%;
  }
  a{
    padding: 0.7rem 0;
    color: #fff;
    font-size: 1.1rem;
      display: block;
      text-align: center;
  }
}
.container-fluid {
  max-width: 1600px;
  margin: 0 auto;
}
main {

}
.logo {
  max-width: 385px;
  display: inline-block;
  width: 100%;
}
.slider {
  background: url('../img/bg-mobile.jpg') no-repeat top center #292929;
  background-size: 60%;
  color: #fff;
  text-align: center;
  padding: 4rem 0 1rem;
  margin-bottom: 2rem;
}
.start {
    background-size: 90%;
  padding: 11rem 0 3rem;
}

.btn-white {
  color: #fff;
  font-weight: 600;
  border: 2px solid $white;
  background: none;
  padding: 0.8rem 1.3rem;
  margin: 0.5rem 0;
  display: inline-block;
  &:hover {
    background: $white;
    color:$green;
  }
}
.button {
  background: $green;
  color: $white;
  padding: 0.8rem 1.3rem;
  margin: 0.4rem 0 1.2rem;
  display: inline-block;
  font-weight: 600;
  &:hover {
    opacity: 0.8;
  }
}
.content {
  margin:2.0rem 0;
}
.bluebg {
  background: $blue;
  padding: 3rem 0;
  text-align: center;
}
.intro {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.box {
  background: $white;
  padding: 2rem;
  box-shadow: 0 0 7px 0 rgba(0,0,0,0.10);
  margin: 1rem 0;
  text-align: left;
}
ul.list li {
  margin-bottom: 1.2rem;
  line-height: 1.6rem;
}
ul.list li:before {
  content: '✓';
  color: $green;



}
.vorstand .box {
  padding: 1rem;
  text-align: left;
  margin: 1rem 0;

  font-size: 0.9rem;
  h4 {
    color: $green;
    margin-bottom: 0.5rem;
    margin-top: 0.3rem;
    font-size: 1.1rem;
  }
  h5 {
    margin-bottom: 0.4rem;
    font-size: 1.0rem;
  }
  p{
    margin-bottom: 0;
  }
}
.antrag {
    background: $greenlight;
    border-left: 8px solid $green;
    padding: 2.3rem 3rem 1.9rem;
    margin: 2.5rem 0;
    h3 {
      margin-bottom: 0;
    }
    .button {
      margin-top: 0.9rem;
    }
}
.boxes {
  margin: 1rem 0 2rem;
}
.textthumb {
  width: 100%;
}
@media screen and (min-width: 1100px){
  .toprow, .header {
    text-align: left;
    font-size: 0.8rem;
  }
  .fontbig {
    font-size: 1.2rem;
  }
  .textthumb {
    max-width: 160px;
    float: left;
    margin: 0 1rem 0rem 0;
  }
  .antrag {
    margin: 3.5rem 0;
    .button {
      float: right;
    }
  }
  .box {
    margin: 0;
    position: relative;
    height: 100%;
  }
  .vorstand .box {
    position: static;
    height: auto;
    margin-bottom: 2rem;
  }
  .desktopshow {
    display: block;
  }
  .schnellkontakt {
    display: flex;
    text-align: right;
    justify-content: flex-end;
    .icon {
      padding-left: 2rem;
      margin-left: 2rem;
    }
    .icon:last-child {
      border-left: 1px solid $grey;
    }
    img {
      max-height: 20px;
      position: relative;
      left: -7px;
      top: 2px;
    }
    a {
      color: $black;
      padding: 0.7rem 0;
      display: block;
    }
  }
  .mobiledisplay {
    display: none;
  }
  .navigation {
    padding-left: 15px;
    display: block !important;
    padding-right: 15px;
  }
  nav {
    background: none;
    font-size: 0.9rem;
    margin-top: 1.1rem;
    ul {
      display: flex;
      justify-content: flex-end;
    }
    li {
      border: 0;
      padding: 0 0.7rem;
    }
    a {
      color: $black;
    }
    a:hover,.active {
      color:$green;
    }
  }
  .header {
    padding-bottom: 1rem;
    border-bottom: 1px solid $grey;
  }
  .footernav {
    ul {
      display: flex;
      justify-content: space-between;
      li {
        flex: auto;
      }
      a:hover {
        color: $green;
      }
    }
  }
  .slider {
    background-image: url("../img/bg.jpg");
    text-align: left;
    background-size: contain;
    padding: 4rem 2rem 3rem;
  }
  .start {
    padding: 5rem 2rem;
    background-size: cover;
  }
}

@media screen and (min-width: 1300px){
  nav {
    font-size: 1rem;
  }
  .content {
    padding: 4rem 0;
  }
}
